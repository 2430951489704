






















import { Component, Mixins, Watch } from 'vue-property-decorator'
import { ResourceActionFailed } from '@movecloser/front-core'

import { AbstractModuleUi } from '../../abstract/ui'
import { UiMarkdown } from '../../partials/UiMarkdown'

import { ImageProps } from '../../../dsl/atoms/Image'
import { toImageProps } from '../../../front/shared/support'
import { AuthMixin, IAuthMixin } from '../../../front/auth/shared'
import { BaseCartMixin, IBaseCart } from '../../../front/checkout/shared/mixins/base-cart.mixin'

import { ProductsSetModule } from '../ProductsSet.contracts'
import { IToastsService, ToastsServiceType, ToastType } from '../../../front/shared/services'
import { Inject, logger } from '../../../support'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<ProductsSetModuleUI>({
  name: 'ProductsSetModuleUI',
  components: { UiMarkdown },
  mounted (): void {
    this.calculatePrice()
  }
})
export class ProductsSetModuleUI extends Mixins<
  AbstractModuleUi<ProductsSetModule>,
  IBaseCart, IAuthMixin>(AbstractModuleUi, BaseCartMixin, AuthMixin) {
  /**
   * Instance of toast service.
   */
  @Inject(ToastsServiceType, false)
  private readonly toastService?: IToastsService

  public isAddingToCart: boolean = false
  public price: number | null = null

  /**
   * Constructs image props with respect of overridden values.
   */
  public get image (): ImageProps {
    if (!this.content.thumbnail) {
      throw new Error('ImageModuleUi(): Image is [null]')
    }

    const translatedImage: ImageProps = toImageProps(this.content.thumbnail)

    const { alt, caption, title } = { ...this.content.thumbnail }

    return {
      ...translatedImage,
      alt: alt ?? '',
      caption,
      title
    }
  }

  /**
   * @override
   */
  public get shouldRender (): boolean {
    return !!this.content.products && this.content.products.length > 0
  }

  public async addToCart (): Promise<void> {
    const cartId = this.$store.getters['checkout/cartId']

    try {
      this.isAddingToCart = true
      const productsToAdd = this.content.products?.map((productEntry) => {
        return {
          sku: Object.values(productEntry.product.variants)[0].sku,
          quantity: productEntry.count ?? 1
        }
      }) ?? []

      const cart = await this.cartService.addManyToCart(cartId, productsToAdd)
      await this.loadCart(cart.id)

      this.toastService?.show(this.$t('front.products.organisms.ProductsSet.addedToCart').toString(),
        ToastType.Success)
    } catch (e) {
      if (e instanceof ResourceActionFailed) {
        this.toastService?.show(e.message, ToastType.Danger)
      }

      logger(e, 'warn')
    } finally {
      this.isAddingToCart = false
    }
  }

  protected calculateWithNoGroup (): void {
    this.price = this.content.products?.reduce((acc, next) => {
      return (acc + (Object.values(next.product.variants)[0].price.finalPrice * (next.count ?? 1)))
    }, 0) ?? null
  }

  protected calculatePrice (): void {
    const notLoggedInUserPriceGroup = this.content.categoryPrices
      ? this.content.categoryPrices.F : null

    const loggedInUserPriceGroup = this.content.categoryPrices
      ? this.content.categoryPrices.D : null

    if (notLoggedInUserPriceGroup && !this.isLoggedInUser) {
      const priceCandidate =
        notLoggedInUserPriceGroup.promotion.length
          ? notLoggedInUserPriceGroup.promotion
          : (notLoggedInUserPriceGroup.regular.length
            ? notLoggedInUserPriceGroup.regular : null)

      if (priceCandidate) {
        this.price = Number(priceCandidate)
      } else {
        this.calculateWithNoGroup()
      }
    }

    if (loggedInUserPriceGroup && this.isLoggedInUser) {
      const priceCandidate =
        loggedInUserPriceGroup.promotion.length
          ? loggedInUserPriceGroup.promotion
          : (loggedInUserPriceGroup.regular.length
            ? loggedInUserPriceGroup.regular : null)

      if (priceCandidate) {
        this.price = Number(priceCandidate)
      } else {
        this.calculateWithNoGroup()
      }
    }
  }

  @Watch('isWaitingForAuth')
  private onAuth (value: boolean): void {
    this.calculatePrice()
  }
}
export default ProductsSetModuleUI
